import type { BaseObjectI, SimpleCompanyI } from "~/stores/manuals/ManualsInterface";

export const UID = 14;
export const CONSTRUCT = 8;

export interface TariffI {
  id: number,
  title: string,
  isHit: boolean,
  hitLabel?: string,
  description: string,
  descriptionHint: string,
  priceHint?: string,
  options: number[],
  cost: number,
  users: number,
  isCostFinal: boolean,
}

export interface TariffPeriodI {
  duration: number,
  discount: number,
  durationLabel: string,
  discountLabel: string | undefined,
}

export interface InvoiceI {
  id: number|undefined,
  invoiceNumber: string,
  company?: SimpleCompanyI,
  companyTitle: string,
  companyInn: string,
  companyKpp: string,
  companyLegalAddress: string,
  tariff: BaseObjectI | undefined,
  tariffDuration: number | undefined,
  cost: number | undefined,
  dateCreate: string,
  status?: BaseObjectI,
}

export const TariffsPeriodConst : TariffPeriodI[] = [
  { duration: 1, discount: 1, durationLabel: '1 месяц', discountLabel: undefined },
  { duration: 6, discount: 0.85, durationLabel: '6 месяцев', discountLabel: '-15%' },
  { duration: 12, discount: 0.8, durationLabel: '12 месяцев', discountLabel: '-20%' },
]

export const TariffsListConst : TariffI[] = [
  {
    id: 1,
    title: 'Минимальный',
    isHit: false,
    description: 'Для тех, кто редко участвует в закупках',
    descriptionHint: 'Минимальная цена за доступ к полной базе государственных и коммерческих тендеров',
    options: [2, 3, 10, 11, 18, 19],
    cost: 1140,
    users: 1,
    isCostFinal: true,
  },
  {
    id: 2,
    title: 'Я - Специалист',
    isHit: true,
    hitLabel: 'Хит',
    description: 'Для специалистов по работе с тендерами',
    descriptionHint: 'Широчайшие возможности поиска закупок, повседневного контроля и управления своими заявками',
    options: [2, 3, 4, 5, 6, 10, 11, 12, 13, 14, 15, 18, 19, 20, 21],
    cost: 3500,
    users: 3,
    isCostFinal: true,
  },
  {
    id: 4,
    title: 'Максимум',
    isHit: false,
    hitLabel: 'Эксклюзив',
    description: 'Для среднего и крупного бизнеса',
    descriptionHint: 'Полные возможности системы для решения любых задач по поиску и управления тендерами',
    priceHint: "Цена может быть увеличена в случае сложных или нестандартных требований к экспертному поиску",
    options: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21],
    cost: 8700,
    users: 10,
    isCostFinal: false,
  },
]
